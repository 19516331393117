<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>{{ title }} Test Case</h5>
          </div>
          <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="onSubmit" autocomplete="off">
              <div class="ibox-content">
                <div class="row">
                  <div class="form-group col-md-4">
                    <label>Id</label>
                    <ValidationProvider
                      name="Id"
                      :rules="{ required: true }"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        placeholder=""
                        v-model="testCase.id"
                        class="form-control"
                        :class="classes"
                        readonly="true"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group col-md-4">
                    <ValidationProvider
                      name="Test Case Name"
                      :rules="'required|alpha_dash'"
                      v-slot="{ errors, classes }"
                    >
                      <label>Test Case Name *</label>
                      <input
                        type="text"
                        placeholder="Name"
                        v-model="testCase.name"
                        class="form-control"
                        :class="classes"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group col-md-4">
                    <ValidationProvider
                      name="API"
                      :rules="{ is_not: 0 }"
                      v-slot="{ errors, classes }"
                    >
                      <label>API *</label>
                      <select
                        class="group-select form-control"
                        ref="selectedItem"
                        id="api"
                        :class="classes"
                      ></select>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div v-if="Object.keys(testCase.config).length > 0">
                  <a href="#" class="btn btn-xs btn-white" @click="generateBodyData()"
                    ><i class="fa fa-plus"></i> Add
                  </a>
                </div>
                <br />

                <div
                  v-for="(config, index) in testCase.config"
                  v-bind:key="index"
                  class="row"
                >
                  <div class="col-sm-4">
                    <form role="form">
                      <div class="form-group">
                        <label>Content Type</label
                        ><span class="float-right">
                          <a
                            href="#"
                            class="btn btn-xs btn-white"
                            @click="removeBodyData(index)"
                            ><i class="fa fa-trash"></i> Remove
                          </a></span
                        >
                        <input
                          type="text"
                          placeholder=""
                          class="form-control"
                          readonly="true"
                          v-model="config.contentType"
                        />
                      </div>

                      <div class="form-group">
                        <label>Path</label>
                        <input
                          type="text"
                          placeholder=""
                          class="form-control"
                          readonly="true"
                          v-model="config.path"
                        />
                      </div>

                      <div class="row">
                        <div class="form-group col-sm-6">
                          <label>Status Code</label>
                          <select
                            class="group-select form-control"
                            ref="selectedItem"
                            v-model="config.statusCode"
                            id="api"
                          >
                            <option
                              v-for="(code, index) in statusCode"
                              v-bind:key="index"
                              :value="code"
                            >
                              {{ code }}
                            </option>
                          </select>
                        </div>

                        <div class="form-group col-sm-6">
                          <label>Method</label>
                          <input
                            type="text"
                            placeholder=""
                            class="form-control"
                            readonly="true"
                            v-model="config.method"
                          />
                        </div>
                      </div>
                    </form>
                  </div>

                  <div class="form-group col-md-4">
                    <label class="edit-label"
                      >Request Body *
                      <i
                        class="fa fa-pencil-square-o pos-edit-icon"
                        @click="editSchema(index, 'request')"
                      ></i
                    ></label>
                    <textarea
                      class="form-control"
                      placeholder="Request Body..."
                      v-model="config.requestBody"
                      rows="8"
                      cols="10"
                      readonly="true"
                    ></textarea>
                  </div>

                  <div class="form-group col-md-4">
                    <label class="edit-label"
                      >Response Body *
                      <i
                        class="fa fa-pencil-square-o pos-edit-icon"
                        @click="editSchema(index, 'response')"
                      ></i
                    ></label>
                    <textarea
                      class="form-control"
                      placeholder="Response Body..."
                      v-model="config.responseBody"
                      rows="8"
                      cols="10"
                      readonly="true"
                    ></textarea>
                  </div>
                </div>

                <br />
                <div class="form-group row">
                  <div class="col-sm-4 col-sm-offset-2">
                    <button class="btn btn-white btn-sm" type="button">Clear</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                      class="btn btn-primary btn-sm ladda-button"
                      data-style="zoom-in"
                      type="submit"
                      :disabled="invalid"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Constant from "../plugin/Constant.js";
import Utils from "../plugin/Utils";
import AxiosService from "../plugin/AxiosService.js";
const SwaggerParser = require("@apidevtools/swagger-parser");
const { JSONPath } = require("jsonpath-plus");
export default {
  data() {
    return {
      testCase: {
        name: "",
        id: Utils.uuid(),
        api: "",
        config: [],
        definition: "",
      },
      title: Constant.data.ADD_TITLE,
      url: Constant.url.TEST_CASE_URI_ADD,
      apiKeyValue: [],
      apiData: [],
      testApiBody: {},
      statusCode: Constant.statusCodes,
      isUpdate: false,
    };
  },
  computed: {
    config() {
      console.log("Updated...");
    },
  },
  created: function () {
    this.fetchapiData();
  },
  mounted: function () {
    const self = this;
    setTimeout(() => {
      Utils.initalizedSelect2("#api", self.apiKeyValue);
    }, 300);
    $("#api").change(function (event) {
      self.testApiBody = Utils.getApiDefinition(self.apiData, $(this).val());
      if (self.isUpdate) {
        self.testCase.api = $(this).val();
        self.testCase.config = [];
        if (!Utils.isEmptyStr($(this).val())) {
          self.generateBodyData();
        }
      }
    });
    setTimeout(() => {
      this.loadTestData();
    }, 400);
  },
  watch: {
    apiData: function (data) {
      var self = this;
      self.apiKeyValue.push({ id: 0, text: "---- Select ----", selected: true });
      $.each(data, function (key, value) {
        self.apiKeyValue.push({ id: value.name, text: value.name });
      });
    },
  },
  methods: {
    fetchapiData: function () {
      var self = this;
      AxiosService.get(Constant.url.API_ALL).then((result) => {
        self.apiData = result;
      });
    },
    addTest: function () {},
    editSchema: function (index, input) {
      var initial = JSON.parse(
        input == "request"
          ? this.testCase.config[index].requestBody
          : this.testCase.config[index].responseBody
      );
      var editorOptions = {};
      var element = document.getElementById("schemaContainer");
      try {
        this.schemaEditor = new JSONEditor(element, editorOptions);
        this.schemaEditor.set(initial);
        this.schemaEditor.expandAll();
        schemaEditorClose = function () {
          this.schemaEditor.destroy();
          $("#myModal").modal("hide");
        }.bind(this);
        schemaEditorSave = function () {
          var ouput = JSON.stringify(this.schemaEditor.get(), null, 4);
          if (input == "request") {
            this.testCase.config[index].requestBody = ouput;
          } else {
            this.testCase.config[index].responseBody = ouput;
          }
          schemaEditorClose();
        }.bind(this);
        $("#schemaModalTitle").text("Schema Editor ");
        $("#myModal").modal("show");
      } catch (ex) {
        console.log(ex);
      }
    },
    generateBodyData() {
      const self = this;
      try {
        if (!Utils.isEmptyStr(this.testApiBody)) {
          SwaggerParser.validate(jsyaml.load(this.testApiBody), (err, api) => {
            if (err) {
              console.error(err);
            } else {
              
              if(JSONPath({ path: Constant.jsonPath.REQUEST_BODY, json: api })[0] != undefined) {
                var $requestForm = $("<form id='apiData'></form>");
                $("body").append($requestForm);
                var form = $("#apiData").jsonForm(
                  JSONPath({ path: Constant.jsonPath.REQUEST_BODY, json: api })[0]
                );
              }

              if(JSONPath({ path: Constant.jsonPath.RESPONSE_BODY, json: api })[0] != undefined) {
                var $responseForm = $("<form id='apiData1'></form>");
                $("body").append($responseForm);
                var responseForm = $("#apiData1").jsonForm(
                  JSONPath({ path: Constant.jsonPath.RESPONSE_BODY, json: api })[0]
                );
              }

              var parameters;
              
               if(JSONPath({ path: Constant.jsonPath.PARAMETERS, json: api }) != undefined) {
                parameters = Utils.getParametersObject(JSONPath({ path: Constant.jsonPath.PARAMETERS, json: api }));
              } 
              
              self.testCase.config.push({
                requestBody: Utils.isEmptyStr($("#apiData").jsonFormValue()) ? JSON.stringify(parameters, null, 4) : JSON.stringify($("#apiData").jsonFormValue(), null, 4) ,
                contentType: JSONPath({
                  path: Constant.jsonPath.MEDIA_TYPE,
                  json: api,
                })[0],
                responseBody: JSON.stringify($("#apiData1").jsonFormValue(), null, 4),
                path: JSONPath({
                  path: Constant.jsonPath.PATH,
                  json: api,
                })[0],
                method: JSONPath({
                  path: Constant.jsonPath.METHOD,
                  json: api,
                })[0],
                statusCode: 200,
              });
              $("#apiData").remove();
              $("#apiData1").remove();
              console.log(self.testCase.config);
              self.$forceUpdate();
            }
          });
        }
      } catch (error) {
        console.err(error);
      }
    },
    removeBodyData: function (index) {
      this.testCase.config.splice(index, 1);
      console.log(this.testCase.config);
      this.$forceUpdate();
    },
    onSubmit: function () {
      const self = this;
      this.testCase.definition = JSON.stringify(this.testCase.config);
      AxiosService.post(this.url, this.testCase).then((result) => {
        if (
          Utils.hasValue(result, Constant.data.RESPONSE_CODE) &&
          result.responseCode == Constant.data.SUCCESS
        ) {
          self.$router.replace(Constant.url.SEPARATER + Constant.url.TEST_CASE_VIEW_URI);
        }
      });
    },
    loadTestData: function () {
      var testId = this.$route.query.id;
      const self = this;
      if (testId != undefined) {
        AxiosService.get(Constant.url.TEST_CASE_URI + Constant.SEPARATER + testId).then(
          (result) => {
            self.testCase.id = result.id;
            self.testCase.name = result.name;
            self.testCase.config = [];
            console.log(result.api);
            self.testCase.config = JSON.parse(result.definition);
            $("#api").select2("val", $("#api option:contains(" + result.api + ")").val());
            self.testCase.api = result.api;
            self.isUpdate = true;
          }
        );
        this.title = Constant.data.UPDATE_TITLE;
        this.url = Constant.url.TEST_CASE_URI_UPDATE;
      } else {
        this.isUpdate = true;
      }
    },
  },
};
</script>
<style scoped>
.edit-label {
  width: 100%;
}
.pos-edit-icon {
  margin-left: 1%;
}
</style>
